.btn {
    @apply inline-block px-4 py-2 rounded-lg no-underline shadow-lg font-medium;

    &:hover {
        @apply no-underline;
    }

    &.btn-lg {
        @apply text-xl px-8 py-3;
    }

    &.btn-primary {
        @apply bg-blue-500 text-white;

        &:hover {
            @apply bg-blue-600 text-white;
        }
    }
}
