@import "tailwindcss/base";
@import "tailwindcss/components";

@import '_general.pcss';
@import '_angle.pcss';
@import '_buttons.pcss';
@import '_forms.pcss';
@import '_notch.pcss';

@import "tailwindcss/utilities";
