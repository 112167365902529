.angle-section {
    padding: 20vw 0;

    .angle-bg {
        @apply flex flex-col h-full;

        .angle-mask {
            @apply flex flex-col overflow-hidden;
            height: 23.5vw;

            .angle {
                width: 100vw;
                height: 25vw;
                transform: skew(0deg, 13deg);
                transform-origin: 0% 0%;

                &.inverted {
                    transform: skew(0deg, -13deg);
                }

                &.mirror {
                    transform-origin: 100% 0%;
                }
            }
        }
    }
}

@screen lg {
    .angle-section {
        margin: -8vw 0;
        padding: 16vw 0;
    }
}