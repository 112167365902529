[x-cloak] {
    @apply hidden;
}

a {
    @apply text-blue-500 underline;

    &:hover {
        @apply underline text-blue-600;
    }
}
