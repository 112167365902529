label {
    @apply inline-block mb-2;
}

form {
    @apply m-0;
}

input[type="text"],
input[type="password"],
input[type="tel"],
textarea {
    @apply border border-gray-400 p-2 rounded block w-full;

    &:disabled,
    &[readonly] {
        @apply bg-gray-100;
    }

    &.is-invalid {
        @apply border-red-600;
    }
}
